/* globals VXConfig */
import {
	scrollToElem,
	isTouchDevice,
	getUrlParams,
	removeClass,
	getElementHeight,
	getElementWidth,
	addClass,
	getOffsetTop, getOffsetLeft, shouldShowEsAVSModal
} from '../utils/CommonUtils';
import React                            from 'react';
import ReactTooltip                     from 'react-tooltip';
import {render, unmountComponentAtNode} from 'react-dom';
import {VXPay}                          from '../utils/VXPay';
import PjaxWrapper                      from './../utils/PjaxWrapper';
import ReloadHelper                     from './../utils/ReloadHelper';
import Flux                             from './../flux/Flux';
import {openModalBox, ModalBoxEnum}     from "../components/ModalBox/ModalBoxFactory";
import WebmasterCookieHelper            from "../utils/WebmasterCookieHelper";
import ModalBoxTrialConversionChat
	               from "../components/ModalBox/ModalBoxTrialConversionChat";
import 'core-js/modules/es6.string.includes'; //polyfill for ie11
import {showLoader}                            from '../components/Loader/LoaderFactory';
import {BonusCodeInfoBannerWithNotPayingGuard} from '../components/BonusCodeInfoBanner';
import "./generatedViews.js";
import "../../../src/VX/Asset/Less/VX/CriticalDesktop.less";
import "../../../src/VX/Asset/Less/VX/index.less";

let winScrollTimer;

/**
 * init tooltips
 */
function initTooltips() {
	if (!isTouchDevice()) {
		const container = document.createElement('div');
		document.body.append(container);

		render(<ReactTooltip globalEventOff="click"/>, container);
	}
}

/**
 * init material effect on buttons that are using css class "-click-material"
 *
 */

function initClickMaterial() {
	const _onReady = function() {
		let ink, diameter;

		document.querySelectorAll('.-show-material').forEach((el) => {
			el.addEventListener('click', attachMaterial);
		});

		const clickMaterial = document.querySelector(".-click-material");
		if (clickMaterial) {
			clickMaterial.addEventListener('click', attachMaterial);
		}

		function attachMaterial(e) {
			if (!e.target.querySelector(".ink")) {
				const el = document.createElement('span');
				el.classList.add('ink');
				e.target.prepend(el);
			}

			ink = e.target.querySelector(".ink");
			removeClass(ink, 'animate');

			if (!getElementHeight(ink) && !getElementWidth(ink)) {
				diameter         = Math.max(e.target.offsetWidth, e.target.offsetHeight);
				ink.style.height = diameter;
				ink.style.width  = diameter;
			}

			// take cursor position or take a fallback
			const x = e.pageX - getOffsetLeft(e.target) - getElementWidth(ink) / 2;
			const y = e.pageY - getOffsetTop(e.target) - getElementHeight(ink) / 2;

			ink.style.top  = y + 'px';
			ink.style.left = x + 'px';
			addClass(ink, 'animate');

			window.setTimeout(function() {
				ink.style.top  = 0 + 'px';
				ink.style.left = 0 + 'px';
			}, 500);
		}

        const urlParams = getUrlParams(window.location.href);
        if (typeof urlParams.openModal !== 'undefined') {
            openModalBox(urlParams.openModal);
        }

        if (typeof urlParams.openFeedback !== 'undefined') {
            Flux.Survey.openSurvey(Flux.Constants.SurveyTypes.FEEDBACK);
        }

        if (shouldShowEsAVSModal()) {
            openModalBox(ModalBoxEnum.ES_AVS, {overCookie: true});
        }

		if (VXConfig.loginBonus) {
			openModalBox(ModalBoxEnum.SEVEN_DAYS_BONUS, {overCookie: true});
		}

		if (VXConfig.allGuestPreferences && VXConfig.allGuestPreferences.length > 0) {
			openModalBox(ModalBoxEnum.ONBOARDING, {overCookie: true});
		}

	};

	if (document.readyState !== 'loading') {
		_onReady();
	} else {
		window.addEventListener('DOMContentLoaded', _onReady);
	}
}

/**
 * Just a general scroll handler
 */
function onScrollHandler() {
	if (winScrollTimer) {
		return;
	}

	winScrollTimer = window.setTimeout(() => {
		showScrollToTop();
		toggleStickyNav();

		window.clearTimeout(winScrollTimer);
		winScrollTimer = null;
	}, 50);
}

/**
 * function is call when user scrolls
 * show|hide to top button
 */
function toggleStickyNav() {
	if (!VXConfig.device.isMobile) {
		if ((window.pageYOffset || document.documentElement.scrollTop) > 100) {
			document.body.classList.add('has-sticky-main-nav');
			document.body.classList.remove('has-not-sticky-main-nav');
		} else {
			document.body.classList.remove('has-sticky-main-nav');
			document.body.classList.add('hhas-not-sticky-main-nav');

		}

		if (window.pageYOffset > 100) {
			// set sticky nav
			setNavIntoSticky();
		} else {
			// remove sticky nav
			setNavIntoNonSticky();
		}
	}
}

/**
 * Changes NavbarSpecialElements to sticky
 */
function setNavIntoSticky() {
	// only execute if not already in sticky-nav
	if (!document.getElementById('navbar-active-tmp') && document.getElementById("navbar-active")) {
		// add css sticky less-class
		addClass(document.getElementById('navbar-active'), 'navbar-sticky');
		// move bar to the main-navigation and rename it
		const navbarActive = document.getElementById("navbar-active");
		navbarActive.id    = "navbar-active-tmp";
		document.getElementById('navbar-sticky').append(navbarActive);

	}
	// cleanup wrong navbar
	cleanupNavbarElements('navbar-active');
}

/**
 * Changes NavbarSpecialElements out of sticky to normal
 */
function setNavIntoNonSticky() {
	// only execute if not already in non-sticky-nav
	if (document.getElementById('navbar-active-tmp') && !document.getElementById('navbar-active')) {
		// remove css sticky less-class
		removeClass(document.getElementById("navbar-active-tmp"), 'navbar-sticky');
		// move bar back to original position
		const navbarActiveTmp = document.getElementById("navbar-active-tmp");
		navbarActiveTmp.id    = "navbar-active";

		document.getElementById('navbar-default').append(navbarActiveTmp);
	}
	// cleanup wrong navbar
	cleanupNavbarElements('navbar-active-tmp');
}

function cleanupNavbarElements(id) {
	// remove all duplicates
	while (document.getElementById(id)) {
		document.getElementById(id).remove();
	}
}

/**
 * function is call when user scrolls
 * show|hide to top button
 */
function showScrollToTop() {
	const item = document.getElementById('scrollToTop');
	if (item) {
		if ((window.pageYOffset || document.documentElement.scrollTop) > 950) {
			if (item.style.display === 'none') {
				item.style.display = 'block';
			}
		} else {
			item.style.display = 'none';
		}
	}
}

/**
 * add click event to element
 */
function initScrollToTop() {
	const _onReady = function() {
		const item = document.getElementById('scrollToTop');
		if (item) {
			item.onclick = scrollToTop;
		}
	};

	if (document.readyState !== 'loading') {
		_onReady();
	} else {
		window.addEventListener('DOMContentLoaded', _onReady);
	}
}

function scrollToTop() {
	scrollToElem('head-container');
}

/**
 * @param {Boolean} usePjax
 * @param {string} abTestAvsContainerId
 * @param lessUrl
 */
function run() {
	window.hasRunEntryFunc = true;
	polyFill();

	const {
		      usePjax,
		      abTestAvsContainerId,
	      } = window.indexData;

	// conversion chat candidate check
	if (Flux.Guest.isCandidateForConversionChat()) {
		const date = new Date(window.VXConfig.conversionChatCandidateCountdownEnd * 1000);

		const pincallContainer = document.createElement('div');
		pincallContainer.id    = 'pincall-container';
		document.body.appendChild(pincallContainer);

		render(<ModalBoxTrialConversionChat end={date} onClose={() => {
			unmountComponentAtNode(pincallContainer);
		}}
		/>, pincallContainer);
	}

	WebmasterCookieHelper.setWebmasterCookiesOnUserInteraction();

	if (VXConfig.isEntryPage && VXConfig.entryPageId === Flux.Constants.EntryPage.Ids.ADWORDS_STARTPAGE) {
		document.addEventListener('click', function(e) {
			e.stopPropagation();
			e.preventDefault();
			VXPay.openSignupOrLogin();
		}, true);
	}

	const urlParams = getUrlParams(window.location.href);

	if (typeof urlParams.openFeedback !== 'undefined') {
		Flux.Survey.openSurvey(Flux.Constants.SurveyTypes.FEEDBACK);
	}

	if (typeof urlParams.openModal !== 'undefined') {
		openModalBox(urlParams.openModal);
	}

	// disable IE11 back navigation caching
	window.addEventListener('beforeunload', function() {
		// do nothing. Important for IE11 to disable back navigation caching
	});

	// add listener for scroll to top functions
	window.addEventListener("scroll", onScrollHandler);

	initTooltips();
	initScrollToTop();
	initClickMaterial();

	// renew accessToken if it need to be regenerated
	document.addEventListener('ajaxSuccess', (e, xhr) => {
		const origin = window.location.origin;

		if (xhr.responseURL && xhr.responseURL.substring(0, origin.length) === origin) {
			const newAccesstoken = xhr.getResponseHeader('accessToken');

			if (newAccesstoken && newAccesstoken !== window.VXConfig.accessToken) {
				window.VXConfig.accessToken = newAccesstoken;
			}
			const newAccesstokenTTL = parseInt(xhr.getResponseHeader('accessTokenTTL'));
			if (newAccesstokenTTL) {
				Flux.Browser.updateExpireStamp(newAccesstokenTTL);
			}
		}
	});

	// Pjax: init callback
	if (usePjax) {
		PjaxWrapper.init({
			elements:  "a.js-pjax-link",
			selectors: ["#seo-title", "#seo-description", ".seo-hreflang", "#seo-meta-robots", "#seo-canonical-link", "#seo-pagination-link-prev", "#seo-pagination-link-next", "#main-navigation-links-burger", "#main-navigation-links", "#main-navigation-links-sticky", "#sub-navigation", "#vx-content-container", "#loader-container", "#footer-container"],
			scrollTo:  false,
			cacheBust: false,
			onError:   (e) => {
				// not authorized?
				if (e.response && e.response.status === 401 && typeof e.response.responseText === 'string') {
					try {
						const data = JSON.parse(e.response.responseText);

						if (data) {
							VXPay.openSignupOrLogin({
								host:     data.actorId,
								hostData: {
									teaserText: VXPay.userAlreadySignedUp ? data.teaserTextLogin : data.teaserText,
								},
							});

							if (data.requestUri) {
								VXPay.onSuccess((result, oldSuccess) => {
									result.redirectTo = data.requestUri;
									oldSuccess(result);
								});
							}

							return false;
						}
					} catch (e) {
						// do nothing
					}
				}

				// workaround because pjax does not change url on redirects
				if (e.response && e.response.status === 400 && typeof e.response.responseText === 'string') {
					const data = JSON.parse(e.response.responseText);

					if (data && data.targetUrl) {
						PjaxWrapper.setTemporaryScrollToTop();
						ReloadHelper.reload(data.targetUrl);

						Flux.Browser.setShowStatusBar(true);
					}

				} else if (e.url) {
					window.location.href = e.url;
				}
			},
		});
	}

	// A/B-Test AVS-Modal
	if (abTestAvsContainerId !== null && Flux.Guest.isLoggedIn() && !Flux.Guest.hasAvs() && window.VXConfig.forceAVSModal) {
		const abContainer = document.getElementById(abTestAvsContainerId);
		const ABTestAVS   = <div className="ab_test_avs-overlay-desktop" onClick={VXPay.openAvsWithLogout}/>;

		render(ABTestAVS, abContainer);
	}

	// handle expiration check
	Flux.Browser.updateExpireStamp(window.VXConfig.accessTokenTTL);
	if (Flux.Browser.checkExpireStamp()) {
		window.location.reload();
	}

	Flux.Guest.init();

	const bonusCodeInfoContainer = document.getElementById('js-bonuscode-hint');
	if (bonusCodeInfoContainer) {
		render(<BonusCodeInfoBannerWithNotPayingGuard />, bonusCodeInfoContainer);
	}

	showLoader();

	document.documentElement.focus();
}

function polyFill() {
	"hasOwn" in Object || (Object.hasOwn = Object.call.bind(Object.hasOwnProperty));
}

if (!window.hasRunEntryFunc) {
	if (document.readyState !== 'loading') {
		run();
	} else {
		window.addEventListener('DOMContentLoaded', run);
	}
}

export {run, setNavIntoNonSticky, initScrollToTop, toggleStickyNav, onScrollHandler, setNavIntoSticky};
